import "../../SCSS/form.scss";
import {useState, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SubjectIcon from '@mui/icons-material/Subject';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import isEmail from 'validator/lib/isEmail';
import Cookies from "encrypted-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux";
import "./../../SCSS/DarkTheme.scss";
function Form() {
    const themeWeb = useSelector((state) => state.Theme.value);
    const API_PATH = 'https://navratil-jiri.cz/api/index.php';

    //uložení form
    const [EmailValue,SetEmailValue] = useState("");
    const [SubjectValue, SetSubjectValue] = useState("");
    const [TextValue, SetTextValue] = useState("");

    const CheckForm = useRef(0);

    const [LoadEmail, SetLoadEmail] = useState(true);
    const [LoadSubject, SetLoadSubject] = useState(true);
    const [LoadText, SetLoadText] = useState(true);
    const [ErEmail, SetErEmail] = useState(false); //pokud je špatně zadaný email

    const HandleSubmit = async(e) => {
        e.preventDefault();
        console.log(EmailValue)
        console.log(SubjectValue)
        console.log(TextValue)
        if(EmailValue !== "" && SubjectValue !== "" && TextValue !== "") {
            if(isEmail(EmailValue)) {
                CheckForm.current = SetCookie()
                if(CheckForm.current <= 3) {
                    const FormData = {
                        Email: EmailValue,
                        Subject: SubjectValue,
                        Text: TextValue
                    };
                    axios({
                        method: 'post',
                        url: `${API_PATH}`,
                        headers: { 'content-type': 'application/json' },
                        data: FormData
                    })
                        .then(result => {
                            SetEmailValue("");
                            SetSubjectValue("");
                            SetTextValue("");
                            SetLoaded();
                            if(result.data.status){
                                toast.success('Úspěšně odesláno!', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: themeWeb,
                                });
                            }
                            else {
                                toast.error('Neúspěch!', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: themeWeb,
                                });
                            }
                        })
                        .catch(error =>console.log(error))
                }
                else {
                    toast.error('Překročení denního limitu!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: themeWeb,
                    });
                }
            }
            else {
                SetErEmail(true);
            }
        }
        else {
            toast.error('Nevyplnil jste požadované pole!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: themeWeb,
            });
        }
    }
    const SetLoaded = () => {
        SetLoadEmail(true);
        SetLoadText(true);
        SetLoadSubject(true);
    }
    const SetCookie = () => {
        let name = Cookies.getCookie('#');
        if(name === "") {
            Cookies.setCookie("#","1", 1)
            return 1;
        }
        else {
            if(name <=3) {
                Number(name++);
                Cookies.setCookie("#",name.toString(), 1);
                return name;
            }
        }
    }
    return (
        <section className={themeWeb === "dark" ? "dark-background form" : "form"} id="contact">
            <Container>
                <Box
                    component="form"
                    Validate
                    autoComplete="off"
                    onSubmit={HandleSubmit}
                >
                    <h3 className="text-white text-center" data-aos="zoom-in" data-aos-duration="600">Kontaktuj mě</h3>
                    <TextField
                        required
                        error={(EmailValue === "" && !LoadEmail) || ErEmail}
                        helperText={ErEmail ? "Špatný formát emailu!" : ""}
                        id="outlined-basic"
                        label="Email"
                        value={EmailValue}
                        inputProps={{ maxLength: 120 }}
                        onChange={event => {SetEmailValue(event.target.value); SetLoadEmail(false); SetErEmail(false)}}
                        type="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MailOutlineIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        required
                        error={SubjectValue === "" && !LoadSubject}
                        id="outlined-basic"
                        label="Předmět"
                        value={SubjectValue}
                        onChange={event => {SetSubjectValue(event.target.value); SetLoadSubject(false)}}
                        InputProps={{
                            maxLength: 30,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SubjectIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="Zpráva"
                        error={TextValue === "" && !LoadText}
                        value={TextValue}
                        onChange={event => {SetTextValue(event.target.value); SetLoadText(false)}}
                        inputProps={{
                            maxLength: 2500,
                        }}
                        required
                        multiline
                        rows={4}
                    />
                    <Button className={themeWeb === "dark" ? "darker-background" : ""} variant="contained" type="submit" endIcon={<SendIcon />}>
                        Odeslat
                    </Button>
                    <ToastContainer/>
                </Box>
            </Container>
        </section>
    )
}
export default Form;