import * as React from 'react';
import CardComponent from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import "../../SCSS/card.scss"
import "../../SCSS/DarkTheme.scss";
import vlak from "../../Images/Projects/vlak.svg"
import {useSelector} from "react-redux";
import {useRef, useState} from "react";
function Card(props) {
    const theme = useSelector((state) => state.Theme.value);
    const TempProjectNumber = useRef(-1);
    const RandomNumberV = useRef();
    const [ProjectNumber, SetProjectNumber] = useState(0);

    //Zatím nevím jak zpracovat v json svg
    const Check = () => {
        if(props.Title !== "Vlak") {
            return props.Image;
        }
        else {
            return vlak;
        }
    }
    const GenerateRandomNumber = (max) => {
        return Math.floor(Math.random() * (max - 0 + 1)) + 0
    }
    const RandomNumber = () => {
        RandomNumberV.current = GenerateRandomNumber(4);
        if(RandomNumberV.current === TempProjectNumber.current) {
            RandomNumber();
        }
        SetProjectNumber(RandomNumberV.current);
        TempProjectNumber.current = RandomNumberV.current;
    }

    return(
        <>
            <CardComponent className="p-3 cardUi" sx={{
                backgroundColor: theme === "dark" ? "#584f62" : "white"}}>
                <CardMedia
                    sx={{ backgroundSize: "contain"}}
                    image={Check()}
                />
                <CardContent>
                    <Typography className={theme === "dark" ? "text-white" : ""} gutterBottom variant="h5" component="div">
                        {props.Title}
                    </Typography>
                    <Typography className={theme === "dark" ? "text-card" : ""} variant="body2" color="text.secondary">
                        {props.Perex}
                    </Typography>
                </CardContent>
                <CardActions className="d-flex justify-content-center align-items-center">
                    {
                        Array.from(props.Btn).map((btn, index) => {
                            if(props.Id === "Special") {
                                return <Button onClick={RandomNumber} variant="contained" className={theme === "dark" ? "darker-background px-4 py-2" : "px-4 py-2"} href={props.Link[ProjectNumber]}  target="_blank">{props.Btn}</Button>
                            }
                            else{
                                return <Button variant="contained"
                                               className={theme === "dark" ? "darker-background px-4 py-2" : "px-4 py-2"}
                                               href={props.Link[index]} target="_blank">{btn}</Button>
                            }})
                    }



                </CardActions>
            </CardComponent>
        </>
    );
}
export default Card;