import Container from "react-bootstrap/Container";
import Button from "@mui/material/Button";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AppsIcon from '@mui/icons-material/Apps';
import React,{useEffect} from "react";
import AOS from "aos";
import "./../../SCSS/DarkTheme.scss";
import 'aos/dist/aos.css';
import {useSelector} from "react-redux";

function Article() {
    const theme = useSelector((state) => state.Theme.value);
    useEffect(() => {
        AOS.init();
    }, [])
    return(
        <article className={theme === "dark" ? "dark-svg darker-background-lg dark-text" : ""} id="about">
            <Container><div className="content d-flex justify-content-center flex-column">
                <h2 data-aos="zoom-in" data-aos-duration="600" >O mně</h2>
                <p data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300" >Mé jméno je Jiří Navrátil a&nbsp;momentálně jsem studentem ve 4. ročníku na škole VOŠ a&nbsp;SPŠ v&nbsp;Jičíně.
                    Nejvíce se zajímám o&nbsp;tvorbu webových aplikací, a&nbsp;to jak z&nbsp;pohledu frontendu, tak i&nbsp;backendu.
                    Kromě oboru se věnuji i&nbsp;fotografování a&nbsp;grafickému designu.</p>
                <div className="d-flex gap-4">
                    <Button variant="contained" href="#skills" className={theme === "dark" ? "dark-background dark-text" : ""} endIcon={<ArrowDownwardIcon />}
                            sx={{
                                borderRadius: '24px',
                                padding: "12px 24px",
                            }}
                    >V čem pracuji?</Button>
                    <Button variant="contained" href="#projects"  className={theme === "dark" ? "dark-background dark-text" : ""}  endIcon={<AppsIcon />}
                            sx={{
                                borderRadius: '24px',
                                padding: "12px 24px",
                            }}>Projekty</Button>
                </div>
            </div>
            </Container>
        </article>
    )
}
export default Article;