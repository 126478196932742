import {createSlice} from "@reduxjs/toolkit"

export const Theme = createSlice({
    name: "theme",
    initialState: {},
    reducers: {
        SetThemeSlice: (state, action) => {
            state.value = action.payload;
        },
        ChangeThemeSlice: (state, action) => {
            state.value = action.payload;
        }
    }
})
export const {ChangeThemeSlice, SetThemeSlice} = Theme.actions;
export default Theme.reducer;