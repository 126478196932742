import Navbar from "./Components/Navbar";
import Card from "./Components/UI/Card";
import Skill from "./Components/Selection/skill";
import Article from "./Components/Selection/Article";
import Form from "./Components/Selection/form";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import "./SCSS/app.scss"
import "./SCSS/DarkTheme.scss";
import Develop from "./Images/web-development-icon.svg"
import Database from "./Images/database-setting-icon.svg"
import Code from "./Images/page-source-code-icon.svg"
import AliceCarousel from 'react-alice-carousel';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import 'react-alice-carousel/lib/alice-carousel.css';
import {ProjectsData} from "./Data/Projects";
import ReactTypingEffect from 'react-typing-effect';
import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useDispatch, useSelector} from "react-redux";
import {SetThemeSlice} from "./features/Theme";
import Cookies from "encrypted-cookie";

function App() {
    const theme = useSelector((state) => state.Theme.value);
    const dispatch = useDispatch()

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 2,
            itemsFit: 'contain'},
        1420: { items: 3,
            itemsFit: 'contain'}
    };

    useEffect(() => {
        AOS.init();

        const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
        //registrace změny theme v prohlížeči
        const handleChange = (event) => {
            const colorScheme = event.matches ? 'dark' : 'light';
            dispatch(SetThemeSlice(colorScheme));
            Cookies.setCookie("Theme", colorScheme,365 );
        };
        mediaQueryList.addEventListener('change', handleChange);

        //změna Theme při prvotním načtení (podle prohlížeče x cookies)
        dispatch(SetThemeSlice(mediaQueryList.matches ? 'dark' : 'light'));
        let SetTheme = Cookies.getCookie("Theme");
        if(SetTheme === "") {
            Cookies.setCookie("Theme", mediaQueryList.matches ? 'dark' : 'light',365 );
        }
        else {
            dispatch(SetThemeSlice(Cookies.getCookie("Theme")));
        }
        return () => {
            mediaQueryList.removeEventListener('change', handleChange);
        };
        }, [dispatch])

    return (
      <>
          <Navbar></Navbar>
            <main className={theme === "dark" ? "dark-background" : ""}>
                <Container>
                <Row>
                    <Col className="d-grid align-items-center">
                        <div className="title w-100">
                            <div className={theme === "dark" ? "dark-me-mobile d-lg-block d-xl-none mobile-photo mb-3" : "d-lg-block d-xl-none mobile-photo mb-3"} ></div>
                            <h1 className={theme === "dark" ? "dark-text" : ""}>Jiří Navrátil</h1>
                            <div>
                            </div>
                            <h3>
                                <ReactTypingEffect
                                    className={theme === "dark" ? "dark-text" : ""}
                                text={["Junior developer", "Fullstack developer", "student IT"]}
                                speed={50}
                                eraseDelay={2500}
                            />
                            </h3>
                            <Button variant="contained" href="#about" className={theme === "dark" ? "darker-background" : ""} endIcon={<ArrowDownwardIcon />}>Více info</Button>
                        </div>
                    </Col>
                    <Col sm={7} className={theme === "dark" ? "d-none d-xl-block present dark-svg" : "d-none d-xl-block present" }>
                        <div className="position-relative w-100 h-100">
                        <div className={theme === "dark" ? "Profile dark-me" : "Profile"}></div>
                        <Row>
                            <Col>
                                <img src={Code} alt="Code" className={theme === "dark" ? "text-dark" : ""} />
                            </Col>
                            <Col className="justify-content-end">
                                <img src={Develop} alt="Develop" />
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col className="justify-content-end">
                                <img className="mt-5" src={Database} alt="Databáze" />
                            </Col>
                        </Row>
                        </div>
                    </Col>
                </Row>
                </Container>
            </main>
          <Article Theme={theme} />
          <Skill Theme={theme}/>
          <section className={theme === "dark" ? "darker-background projects" : "projects"} id="projects">
              <Container>
                  <div className="content d-flex justify-content-center flex-column">
                      <h2 className="mb-3" data-aos="fade-up" data-aos-duration="600" data-aos-delay="100" >Projekty</h2>
                      <AliceCarousel
                            data-aos="fade-up"  data-aos-delay="200" data-aos-duration="1200"
                            mouseTracking
                            responsive={responsive}
                            controlsStrategy="alternate"
                            items={ProjectsData.map((data, key) => {
                                  return(
                                     <Card Title={data.Project} Perex={data.Perex} Link={data.Link} Link2={data.Link2} Image={data.Image} Btn={data.Btn} Id={data.Id}></Card>
                                  );
                            })} />
                  </div>
              </Container>
          </section>
          <Form />
          <footer className={theme === "dark" ? "darker-background text-center p-3 author" : "text-center p-3 author"}>
              <p className="text-white">Jiří Navrátil © {new Date().getFullYear()}</p>
          </footer>
      </>
  );
}

export default App;
