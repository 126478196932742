import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavbarComponent from 'react-bootstrap/Navbar';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useSelector, useDispatch } from 'react-redux'
import {Link} from "react-scroll"
import "./../SCSS/navbar.scss";
import "./../SCSS/DarkTheme.scss";
import {ChangeThemeSlice} from "../features/Theme";
import {useEffect, useRef} from "react";
import Cookies from "encrypted-cookie";

function Navbar() {
    const navbar = useRef(null);
    const Icons = useRef([]);
    const IsOn = useRef(false);
    //true --> pokud není navbar na začátku
    //false --> pokud je navbar na začátku
    const ClickedNavbar = useRef(false);
    //true --> rozkliknutý navbar
    //false --> zavřený navbar
    //redux
    const theme = useSelector((state) => state.Theme.value);
    const dispatch = useDispatch()

    const ChangeTheme = () => {
        dispatch(ChangeThemeSlice(theme === "light" ? "dark" : "light"));
        Cookies.setCookie("Theme", theme === "light" ? "dark" : "light",365 );
    }

    const SetColor = () => {
        if(IsOn.current) {
            !ClickedNavbar.current ? ClickedNavbar.current = true : ClickedNavbar.current = false;
            theme === "dark" ? navbar.current.style.background = "#6D5D6E" : navbar.current.style.background = "white";
            IconsSet("black");
        }
        else {
            if(!ClickedNavbar.current) {
                ClickedNavbar.current = true;
                if(theme === "dark") {
                    navbar.current.style.background = "#6D5D6E";
                }
                else {
                    navbar.current.style.background = "white";
                }
                IconsSet("black");
            }
            else {
                ClickedNavbar.current = false;
                NavbarSetOff();
            }
        }
    }
    const NavbarSetOff = () => {
        navbar.current.style.transition = "0.2s";
        navbar.current.style.background = "";
        navbar.current.style.boxShadow = "none";
        IconsSet("white");
    }
    const IconsSet = (color) => {
        for (let i = 0; i < Icons.current.length; i++) {
            if(theme === "dark") {
                Icons.current[i].style.color = "#fffbf3";
            }
            else {
              Icons.current[i].style.color = color;
            }
        }
    };

    useEffect(() => {
        const changeNavbar = () => {
            if(window.scrollY > 1) {
                if(theme === "dark") {
                    navbar.current.style.backgroundColor = "#6D5D6E";
                }
                else {
                    navbar.current.style.backgroundColor = "white";
                }
                navbar.current.style.transition = "0.2s";
                navbar.current.style.boxShadow = "0 2px 2px -2px black";
                IsOn.current = true;
                IconsSet("black");
            }
            else {
                IsOn.current = false;
                if(!ClickedNavbar.current) {
                    NavbarSetOff();
                }
            }
        };
        window.addEventListener("scroll", changeNavbar);
        //okamžitá změna navbaru
        const body = document.querySelector("body");
        if(window.scrollY > 1) {
            if(theme === "dark") {
                navbar.current.style.backgroundColor = "#6D5D6E";
            }
            else {
                navbar.current.style.backgroundColor = "white";
            }
        }
        //okamžitá změna
        if(theme === "dark") {
            body.style.background = "#4F4557";
            body.style.boxShadow = "none";
        }
        else {
            body.style.background = "white";
            body.style.boxShadow = "inset -28px 6px 202px -6px rgba(69,123,204,1)";
        }
        return () => {
            window.removeEventListener("scroll", changeNavbar);
        };
    }, [theme])

    return(
        <NavbarComponent fixed="top" expand="xl" ref={navbar}>
            <Container>
                <NavbarComponent.Toggle onClick={SetColor} className="hamburger" aria-controls="basic-navbar-nav" placement="end" />
                <NavbarComponent.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link className={theme === "dark" ? "dark-text darker-background-nav darker-background-pseudo nav-link" : "nav-link"} to="about" activeClass="active" spy={true} smooth={true} delay={0} offset={0} duration={0} aria-controls="basic-navbar-nav" aria-label="O mně">O mně</Link>
                        <Link className={theme === "dark" ? "dark-text darker-background-nav darker-background-pseudo nav-link" : "nav-link"} to="skills" activeClass="active" spy={true} smooth={true} delay={0} offset={0} duration={0}  aria-label="V čem pracuji?">V čem pracuji?</Link>
                        <Link className={theme === "dark" ? "dark-text darker-background-nav darker-background-pseudo nav-link" : "nav-link"} to="projects" activeClass="active" spy={true} smooth={true} delay={0} offset={0} duration={0}  aria-label="Projekty">Projekty</Link>
                        <Link className={theme === "dark" ? "dark-text darker-background-nav darker-background-pseudo nav-link" : "nav-link"} to="contact" activeClass="active" spy={true} smooth={true} delay={0} offset={0} duration={0}  aria-label="Kontakt">Kontakt</Link>
                    </Nav>

                </NavbarComponent.Collapse>
                <Nav className="justify-content-end links d-flex flex-row">
                    <Nav.Link href="https://www.facebook.com/profile.php?id=100013296872276"  target="_blank" aria-label="Facebook"><FacebookIcon ref={(el) => (Icons.current[0] = el)} className={theme === "dark" ? "dark-text Icon" : "Icon"}></FacebookIcon></Nav.Link>
                    <Nav.Link href="https://www.youtube.com/channel/UCYQT5TDzkfDZvRDcx9Xe4EQ" target="_blank" aria-label="Youtube"><YouTubeIcon ref={(el) => (Icons.current[1] = el)} className={theme === "dark" ? "dark-text Icon" : "Icon"}> </YouTubeIcon></Nav.Link>
                    <Nav.Link href="https://github.com/jirka88" rel={Icons} target="_blank" aria-label="Github"><GitHubIcon ref={(el) => (Icons.current[2] = el)} className={theme === "dark" ? "dark-text Icon" : "Icon"}></GitHubIcon></Nav.Link>
                    <Nav.Link href="https://www.linkedin.com/in/ji%C5%99%C3%AD-navr%C3%A1til-b63145223/" rel={Icons} target="_blank" aria-label="LinkedIn"><LinkedInIcon ref={(el) => (Icons.current[3] = el)} className={theme === "dark" ? "dark-text Icon" : "Icon"}></LinkedInIcon></Nav.Link>
                </Nav>
                <Nav className="justify-content-end links d-flex flex-row ml-2">
                    <Nav.Link onClick={ChangeTheme} target="_blank" aria-label="Facebook"><DarkModeIcon ref={(el) => (Icons.current[4] = el)} className={theme === "dark" ? "dark-text Icon" : "Icon"}></DarkModeIcon></Nav.Link>
                </Nav>
            </Container>
        </NavbarComponent>
    );
}
export default Navbar;