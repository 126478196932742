export const ProjectsData = [
    {
        Project: "Aplikace pro MS Teams",
        Perex: "Práce se zabývá vytvořením aplikace pro MS Teams, která poskytuje studentům efektivní a individualizovanou výuku, která vypadá jako seznam témat. ",
        Link: ["https://github.com/jirka88/Vyukovy-pavouk"],
        Image: require("./../Images/Projects/MS-Teams-logo.png"),
        Btn: ["Github"],
    },
    {
        Project: "Vlak",
        Perex: "Počítačová hra na způsob originální hry Vlak od Miroslava Němečka. Hra umožňuje jak hrát základní hru (levely), tak i editor, pomocí, kterého můžete vytvářet své levely. ",
        Link: ["https://github.com/jirka88/Vlak-Train","https://youtu.be/-NifzrvUJHg"],
        Image: require("./../Images/Projects/vlak.svg"),
        Btn: ["Github", "Video"],
    },
    {
        Project: "Mini-projekty",
        Perex: "Projekty pro porozumění a naučení JS. (2020)",
        Link: ["https://jn88.tnvr.cz/Whack/whack.html", "https://jn88.tnvr.cz/hra/hra.html","https://jn88.tnvr.cz/pexeso/pexeso.html", "https://jn88.tnvr.cz/ExChanger/smenarna.html", "http://jn88.tnvr.cz/generator/psg.html"],
        Image: require("./../Images/Projects/mole.png"),
        Btn: ["Náhodný projekt"],
        Id: "Special",
    },
]